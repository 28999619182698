import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Social from "./components/Social/Social";
import ScrollToTop from "./ScrollToTop";
const Home = React.lazy(() => import("./components/Home/Home/Home"));
const Sidebar = React.lazy(() => import("./components/Sidebar/Sidebar"));
const About = React.lazy(() => import("./components/About/About/About"));
const Contact = React.lazy(() => import("./components/Contact/Contact/Contact"));
const Blog = React.lazy(() => import("./components/Blog/Blog/Blog"));
const Work = React.lazy(() => import("./components/Work/Work/Work"));

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Sidebar></Sidebar>
        <Social></Social>
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route path="/blog">
            <Blog></Blog>
          </Route>
          <Route path="/work">
            <Work></Work>
          </Route>
          <Route path="/about">
            <About></About>
          </Route>
          <Route path="/contact">
            <Contact></Contact>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
