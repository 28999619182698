import React, { useState } from "react";
import cancelIcon from "../../assets/image/cancel.svg";
import callIcon from "../../assets/image/phone-call.svg";
import "./Social.css";

const socialInfo = [
  {id: 1, icon: <i className="fab fa-github"></i>, link: "https://github.com/asif-jalil" },
  {id: 2, icon: <i className="fab fa-linkedin-in"></i>, link: "https://www.linkedin.com/in/asifjalil0/" },
  {id: 3, icon: <i className="fab fa-facebook-f"></i>, link: "https://www.facebook.com/asif.jalil55/" },
];

const Social = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="social-icon">
      <span onClick={() => setIsOpen(!isOpen)} className="social-toggle d-inline-block d-lg-none">
        {!isOpen ? <img src={callIcon} alt="" /> : <img src={cancelIcon} alt="" />}
      </span>
      <div className={`social-icon-wrapper ${isOpen && "open"}`}>
        {socialInfo.map((info) => (
          <a href={info.link} key={info.id} rel="noreferrer" target="_blank">
            <span className="icon">{info.icon}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Social;
